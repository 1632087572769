@import (less) "normalize.css";

@import (less) "bootstrap-custom";

@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "variables.less";
@import (less) "fonts.less";
@import (less) "helpers.less";
@import (less) "flourish.less";

@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "thankyou.less";

@import (less) "home.less";
@import (less) "offering-slick.less";
@import (less) "now-selling.less";
@import (less) "amenity.less";
@import (less) "news.less";
@import (less) "map.less";
@import (less) "title.less";
@import (less) "footer.less";

@import (less) "mapovis-custom.less";

body,
body.land,
body.house-land,
body.masterplan,
body.townhomes {
  /*padding-top: @menu-offset;*/
  padding-top: 56px;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  body,
  body.land,
  body.house-land,
  body.masterplan,
  body.townhomes {
    /*padding-top: @menu-offset__mob;*/
  }
}

// xs styles
@media (max-width: 767px) {

}