.royalSlider {
    width: 100%;
    //height: 400px;
    position: relative;
    direction: ltr;

    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        // background: #000;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;

        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #000;
        }
    }

    .rsSlide {
        img {
            opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

.rsDefault {
    &.rsHor {
        .rsArrowRight,
        .rsArrowLeft {
            height: 60px;
            background-color: transparent;
            background-position: top left;
            background-repeat: no-repeat;
        }
        .rsArrowLeft {
            .rsArrowIcn {
                //background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                //left: 50px;
            }
        }
        .rsArrowRight {
            .rsArrowIcn {
                //background-image: url('/img/sliders/rsSlider_arrowRight.png');
                //right: 50px;
            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }
}

.rsDefault,
.rsSlide {
    background-color: @core-color__default;
}
