.menu-open {
  overflow: hidden;
  height: 100vh;
  // .mob-menu {
  //   bottom: 0;
  // }
}
.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 10px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
    padding: 0;
		margin-right: 10px;
		text-transform: uppercase;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

.menu-top__0 {
  top: 0;
}


.menu-strip, .menu-container {
  position: fixed;
  top: 0;
  left: 0;
}


.show-submenu {
	opacity: 1;
	top: @menu-offset;
}

.mob-menu {
  position: fixed;
  width: 100%;
  left: 0;
  top: -200vh;
  bottom: 200vh;
  overflow-y: scroll;
  transition: 0.7s ease all;
}

.logo-holder {
  svg {
    max-width: 100%;
  }
}

#mobile-menu {
  // max-height: 0;
  transition: 1s ease all;
  overflow: hidden;
  max-height: 200vh;
  min-height: 100vh;

  li {
    font-size: @sm;
    padding-top:15px;
    padding-bottom:15px;
  > ul {
    max-height:0;
    transition: 1s ease all;
    opacity: 0;
    visibility: hidden;
  }
  .mobile-menu-active {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      opacity: 1;
      visibility: visible;

    }
    .rotated {
      transform: rotate(180deg);
    }
  }
  .mobile-menu-trigger  {
    transition: 0.6s ease all;
    transform-origin: center;
    text-decoration: none;
  }

  .submenu-trigger {
    ul {
      position: absolute;
      left: 0;
      background-color: @sandstone;
      bottom: -34px;
      padding-top: 5px;
      padding-bottom: 5px;
      opacity: 0;
      transition: 1s ease all;
    }
    &:hover {
      > ul {
        bottom:-34px;
        opacity: 1;
      }
    }
  }
}


.hamburger-inner {
  background-color: @white;
  &:before,
  &:after {
    background-color: @white;
  }

}
.hamburger {
  padding: 0;
  &.is-active .hamburger-inner   {
    background-color: @white;
    &:before,
    &:after {
      background-color: @white;
    }

  }
}

// md styles
@media (max-width: 1199px) {
  .menu-container {
    .font-xxs {
      font-size: 11px;
    }
  }
}

// sm styles
@media (max-width: 991px) {
  .mob-menu__active {
    top: 85px;
    bottom: 0;
  }

}

// xs styles
@media (max-width: 767px) {

}