@import 'for';
@import 'variables';

//? Colours and backgrounds
.for(@colours); .-each(@value) {
  @name:  extract(@value, 1);
  @colour: extract(@value, 2);
  .col-@{name} {
      color: @colour;
  }
  .hover-@{name} {
    &:hover {
      color: @colour;
    }
  }
  .bg-@{name} {
    background-color: @colour;
  }
  .bghover-@{name} {
    &:hover {
      background-color: @colour;
    }
  }
  .b-1-@{name} {
    border: 1px solid @colour;
  }
  .bb-1-@{name} {
    border-bottom: 1px solid @colour;
  }
  .bt-1-@{name} {
    border-top: 1px solid @colour;
  }
  .bl-1-@{name} {
    border-left: 1px solid @colour;
  }
  .br-1-@{name} {
    border-right: 1px solid @colour;
  }
  .border-@{name} {
    border-color: @colour;
  }
  .borderhover-@{name} {
    &:hover {
      border-color: @colour;
    }
  }
  .mpv-download-icon-@{name} {
    .stroke {
      stroke: @colour;
    }
    .fill {
      fill: @colour;
    }

  }
  @media (max-width: 991px) {
    .col-@{name}__mob {
      color: @colour;
    }
    .hover-@{name}__mob {
      &:hover {
        color: @colour;
      }
    }
    .bg-@{name}__mob {
      background-color: @colour;
    }
    .bghover-@{name}__mob {
      &:hover {
        background-color: @colour;
      }
    }
    .b-1-@{name}__mob {
      border: 1px solid @colour;
    }
    .bb-1-@{name}__mob {
      border-bottom: 1px solid @colour;
    }
    .bt-1-@{name}__mob {
      border-top: 1px solid @colour;
    }
    .bl-1-@{name}__mob {
      border-left: 1px solid @colour;
    }
    .br-1-@{name}__mob {
      border-right: 1px solid @colour;
    }
  }
};

.for(@sizes); .-each(@value) {
  .mh-@{value}-vh {
    min-height: ~"@{value}vh";
  }
  .mxh-@{value}-vh {
    max-height: ~"@{value}vh";
  }
  .h-@{value}-vh {
    height: ~"@{value}vh";
  }
  .h-@{value}-p {
    height: ~"@{value}%";
  }
  .mh-@{value}-p {
    min-height:  ~"@{value}%";
  }
  .mw-@{value}-vw {
    min-width: ~"@{value}vw";
  }
  .w-@{value}-vw {
    width: ~"@{value}vw";
  }
  .mw-@{value}-p {
    min-width:  ~"@{value}%";
  }
  .w-@{value}-p {
    width:  ~"@{value}%";
  }
  .z-@{value} {
    z-index: @value;
  }
  .bgs-@{value}-p {
    background-size: ~"@{value}%";
  }



  .svg-h-@{value}-px {
    svg{
      height: ~"@{value}px";
    }
  }
  .svg-w-@{value}-px {
    svg{
      width: ~"@{value}px";
    }
  }

  @media (max-width: 991px) {
    .mh-@{value}-vh__mob {
      min-height: ~"@{value}vh !important";
    }
    .h-@{value}-vh__mob {
      height: ~"@{value}vh !important";
    }
    .h-@{value}-p__mob {
      height: ~"@{value}% !important";
    }
    .mh-@{value}-p__mob {
      min-height:  ~"@{value}% !important";
    }
    .mw-@{value}-vw__mob {
      min-width: ~"@{value}vw !important";
    }
    .mxw-@{value}-vw__mob {
      max-width: ~"@{value}vw !important";
    }
    .w-@{value}-vw__mob {
      width: ~"@{value}vw !important";
    }
    .mw-@{value}-p__mob {
      min-width:  ~"@{value}% !important";
    }
    .w-@{value}-p__mob {
      width:  ~"@{value}% !important";
    }


    .svg-h-@{value}-px__mob {
      svg{
        height: ~"@{value}px" !important;
      }
    }
    .svg-w-@{value}-px__mob {
      svg{
        width: ~"@{value}px" !important;
      }
    }
    
  }
};

.svg-w-auto {
  svg{
    width: auto;
  }
}
.svg-h-auto {
  svg{
    height: auto;
  }
}

.border-none {
  border: none;
}

//? background
.bg-cover {
  background-size: cover;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-contain {
  background-size: contain;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-tl {
  background-position: top left;
}
.bg-tc {
  background-position: top center;
}
.bg-tr {
  background-position: top right;
}

.bg-cl {
  background-position: center left;
}
.bg-cc {
  background-position: center center;
}
.bg-cr {
  background-position: center right;
}

.bg-bl {
  background-position: bottom left;
}
.bg-bc {
  background-position: bottom center;
}
.bg-br {
  background-position: bottom right;
}

.tr-1s-ease-all {
  transition: 1s ease all;
}
.transition-default {
  transition: @transition;
}

.postion-relative {
  position: relative;
}

.lh-1 {
  line-height: 1;
}

.lh-0_9 {
  line-height: 0.9;
}

.ls-0_7em {
  letter-spacing: 0.07em;
}
.ls-1_5em {
  letter-spacing: 0.15em;
}
.ls-2em {
  letter-spacing: 0.2em;
}
.ls-4em {
  letter-spacing: 0.4em;
}

.w-fitcontent {
  width: fit-content;
}

.h-fitcontent {
  height: fit-content;
}

.text-decoration-none {
  text-decoration: none;
}
.col-magenta {
  color: #FF00E5 !important;
}
.appearance-none {
  appearance: none;
}
.outline-none {
  outline: none;
}
.no-wrap {
  white-space: nowrap;
}

.image-fit{
  object-fit: cover;
  object-position: center;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .bg-none__mob {
    background-image: none !important;
  }
  .mh-auto__mob {
    min-height: auto !important;
  }
  .b-none__mob {
    border: none;
  }
  .bg-fixed {
    background-attachment: initial;
  }
  .mw-unset__mob{
    max-width: unset !important;
  }

  .svg-w-auto__mob {
    svg{
      width: auto !important;
    }
  }
  .svg-h-auto__mob {
    svg{
      height: auto !important;
    }
  }
}

// xs styles
@media (max-width: 767px) {

}