.bg-home {
  background-image: url('/img/home/banner2.jpg');
}
.bg-aerial {
  background-image: url('/img/home/aerial.jpg');
}

.hero-flourish {
  background-image: url('/img/home/hero-flourish.jpg');
}
.hero-house {
  background-image: url('/img/home/hero-house.jpg');
}
.hero-people {
  background-image: url('/img/home/hero-people.jpg');
}


.hero-summersale {
  background-image: url('/img/home/hero-summersale.jpg');
}
.hero-kids {
  background-image: url('/img/home/hero-kids.jpg');
}
.hero-girl {
  background-image: url('/img/home/hero-girl.jpg');
}
.hero-bike {
  background-image: url('/img/home/hero-bike.jpg');
}
.hero-home {
  background-image: url('/img/home/hero-home.jpg');
}
.hero-glider-park1 {
  background-image: url('/img/home/hero-glider-park1.jpg');
}
.hero-glider-park2 {
  background-image: url('/img/home/hero-glider-park2.jpg');
}
.hero-lavander-rain-park {
  background-image: url('/img/home/hero-lavander-rain-park.jpg');
}