.stage-row {
  background-color: @sandstone;
}
.stage-row__alternate {
  background-color: @sandstone__100;
}
.update-stage {
  height: 4px;
}
.progress-indicator {
  width: 12%;
  margin-right: 0.5%;
  height: 4px;
  float: left;
  background-color: @rust__100;
}
.progress-indicator__active {
  .progress-indicator;
  background-color: @rust;
}