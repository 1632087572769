.flourish-purple {
  background-image: url('/img/flourish/purple-crop.png');
}
.flourish-green {
  background-image: url('/img/flourish/green.png');
}
.flourish-blue {
  background-image: url('/img/flourish/blue.png');
}
.flourish-blue2 {
  background-image: url('/img/flourish/blue2.png');
}
.flourish-orange {
  background-image: url('/img/flourish/orange.png');
}
.flourish-brown {
  background-image: url('/img/flourish/brown.png');
}
.flourish-bouquet {
  background-image: url('/img/flourish/bouquet.png');
}
