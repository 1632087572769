.flyout.is-active {
  top: @menu-offset;
  // sm styles
  @media (max-width: 991px) {
    top: @menu-offset__mob
  }
}




.house-land,
.land,
.townhomes,
.flyout {
  --headerHeight: @menu-offset;

  h1 {

  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {

  }
  p {

  }
  .font-sm {
    font-size: 11px;
  }
  .land-area-lots {
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .mapovis-embed  {
    .sans-bold {
      font-weight: 500;
    }
    .mpv-ion {
      .irs--round {
        .irs-bar {
        background-color: @blue;
        }
        .irs-handle {
          top: 28px;
          width: 18px;
          height: 18px;
          border: 2px solid @blue;
        }
        .irs-from,
        .irs-to,
        .irs-single  {
          background-color: @blue;
          color: @black;
          background-color: transparent;
          &::before {
            border-top-color: @blue;
            border:none;
          }
        }
        .irs-min,
        .irs-max {
          background-color: transparent;
          display: none;
        }
      }
    }
  }

  .mpv-icon_bed {
    width: 18px;
    img {
      max-width: 100%;
    }
  }
  .mpv-icon_bath {
    width: 18px;
    img {
      max-width: 100%;
    }
  }
  .mpv-icon_car {
    width: 20px;
    img {
      max-width: 100%;
    }
  }

  // sm styles
  @media (max-width: 991px) {
    --headerHeight: 86px;
  }

}
