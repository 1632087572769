@import 'for';
@import 'variables';

.offering-type p {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  margin-top: 5px;
}
.slick-dots {
  bottom: 15px;
}
.offering-townhome {
  background-image: url('/img/offering-slider/townhomes.jpg');
}

.offering-townhome2 {
  background-image: url('/img/offering-slider/townhomes-maple-living.jpg');
}


//? Colours and backgrounds
.for(@colours); .-each(@value) {
  @name:  extract(@value, 1);
  @colour: extract(@value, 2);
  .slick-selling[data-dot-col^="@{name}"] {
    .slick-dots {
        li button,
        li.slick-active button  {
          &:before {
            color: @colour;
          }
        }
    }
  }
  // .slick-dots li.slick-active button:beforeslick
}