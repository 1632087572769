.footer {
  .kinbrook-logo-holder {
    path {
      fill: @white;
    }
  }
}
.footer-logo-holder {
  svg {
    width: 100%;
  }
}

.social-icon {
  width: 30px;
  float: left;
  margin-right: 15px;
  svg {
    max-width: 100%;
  }
}