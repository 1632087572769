@font-face {
  font-family: 'Reckless Neue';
  src: url('./../fonts/RecklessNeue-Regular.eot');
  src: url('./../fonts/RecklessNeue-Regular.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/RecklessNeue-Regular.woff2') format('woff2'),
      url('./../fonts/RecklessNeue-Regular.woff') format('woff'),
      url('./../fonts/RecklessNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Reckless Neue';
  src: url('./../fonts/RecklessNeue-RegularItalic.eot');
  src: url('./../fonts/RecklessNeue-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/RecklessNeue-RegularItalic.woff2') format('woff2'),
      url('./../fonts/RecklessNeue-RegularItalic.woff') format('woff'),
      url('./../fonts/RecklessNeue-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeuzeitS';
  src: url('./../fonts/NeuzeitS-Book.eot');
  src: url('./../fonts/NeuzeitS-Book.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/NeuzeitS-Book.woff2') format('woff2'),
      url('./../fonts/NeuzeitS-Book.woff') format('woff'),
      url('./../fonts/NeuzeitS-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeuzeitS';
  src: url('./../fonts/NeuzeitS-BookHeavy.eot');
  src: url('./../fonts/NeuzeitS-BookHeavy.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/NeuzeitS-BookHeavy.woff2') format('woff2'),
      url('./../fonts/NeuzeitS-BookHeavy.woff') format('woff'),
      url('./../fonts/NeuzeitS-BookHeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Balto-Book';
  src: url('./../fonts/Balto-Book.eot');
  src: url('./../fonts/Balto-Book.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Book.woff2') format('woff2'),
      url('./../fonts/Balto-Book.woff') format('woff'),
      url('./../fonts/Balto-Book.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Balto-Medium';
  src: url('./../fonts/Balto-Medium.eot');
  src: url('./../fonts/Balto-Medium.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Medium.woff2') format('woff2'),
      url('./../fonts/Balto-Medium.woff') format('woff'),
      url('./../fonts/Balto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Balto-Light';
  src: url('./../fonts/Balto-Light.eot');
  src: url('./../fonts/Balto-Light.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Light.woff2') format('woff2'),
      url('./../fonts/Balto-Light.woff') format('woff'),
      url('./../fonts/Balto-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Balto-Thin';
  src: url('./../fonts/balto-thin.eot');
  src: url('./../fonts/balto-thin.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/balto-thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

.balto-book {
  font-family: "Balto-Book", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.balto-md {
  font-family: "Balto-Medium", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.balto-light {
  font-family: "Balto-Light", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.balto-thin {
  font-family: "Balto-Thin", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.sans {
  font-family: "NeuzeitS", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.sans-bold {
  font-family:  "NeuzeitS", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.serif {
  font-family: 'Reckless Neue', serif;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.serif-italic {
  font-family: 'Reckless Neue', serif;
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}


@xxl:  70px;
@xl:   58px;
@lg:   44px;
@md:   32px;
@sm:   26px;
@xs:   16px;
@xxs:  14px;
@xxxs: 8px;

.font-xxl,
h1 {
  font-size: @xxl;
}
.font-xl,
h2 {
  font-size: @xl;
}
.font-md,
h3 {
  font-size: @md;
}
.font-sm,
h4 {
  font-size: @sm;
}
.font-xs,
p {
  font-size: @xs;
}
.font-xxs {
  font-size: @xxs;
}
.font-xxxs {
  font-size: @xxxs;
}




// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  @xxl: 40px;
  @xl:  36px;
  @lg:  30px;
  @md:  44px;
  @sm:  28px;
  @xs:  16px;

  .font-xxl__mob,
  h1 {
    font-size: @xxl;
  }
  .font-xl__mob,
  h2 {
    font-size: @xl;
  }
  .font-md__mob,
  h3 {
    font-size: @md;
  }
  .font-sm__mob,
  h4 {
    font-size: @sm;
  }
  .font-xs__mob,
  p {
    font-size: @xs;
  }
  .font-xxs__mob {
    font-size: @xxs;
  }


}

// xs styles
@media (max-width: 767px) {

}